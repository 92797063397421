<template>
    <div class="ticket-display-container">
        <VueDatePicker v-if="!withCustomer" label="Erstellt am" v-model="filterSalesDate" range month-picker auto-apply/>
        <div class="list-view-header">
            <div class="open-wrapper"></div>
            <div class="name-wrapper">
                <label>Ticketinfo</label>
            </div>
            <div class="state-wrapper">
                <label>Status</label>
            </div>
            <div class="price-wrapper">
                <label>Bezahlinfo</label>
            </div>
            <div class="remaining-trips-wrapper">
                <label>Fahrten</label>
            </div>
            <div class="reservations-wrapper">
                <label>Reservierungen</label>
            </div>
            <div class="license-plates-wrapper">
                <label>Kennzeichen</label>
            </div>
            <div class="validity-wrapper">
                <label>Gültigkeit</label>
            </div>
            <div class="actions-wrapper"></div>
        </div>

        <div class="ticket-list-container">
            <div class="ticket-list-wrapper">
                <div class="ticket-list">
                    <TransitionGroup name="ticket-list">
                        <ListTicketDepiction
                            :ref="`ticket-${idx}`"
                            v-for="(ticket, idx) in tickets"
                            :key="idx"
                            :ticket="ticket"
                            :with-customer="withCustomer"
                            :shift-direction="directionId"
                            @open-actions="closeAllTicketActions"
                            @open-details="closeAllTicketDetails"
                            @new-license-plate="plate => addLicensePlateToTicket(ticket, plate)"
                            @remove-license-plate="plate => removeLicensePlateFromTicket(ticket, plate)"
                            @new-validity="({ from, to }) => updateTicketValidity(ticket, from, to)"
                            @cancel-usage="usage =>cancelUsage(ticket, usage)"
                            @new-usage="createUsage(ticket)"
                            @open-customer="handleCustomerSelection(ticket.customer)"
                            @print="printTicket(ticket)"
                            @manual-fee="pushToManualBooking('FEE', ticket)"
                            @manual-refund="pushToManualBooking('REFUND', ticket)"
                            @enable="enableTicket(ticket)"
                            @disable="disableTicket(ticket)"
                            @cancel-ticket="cancelTicket(ticket)"
                        />
                    </TransitionGroup>
                </div>
            </div>
        </div>
        <PopUp :open="showCancel" title="Ticket Stornieren" @close="showCancel = false">
            <CancellationDisplay :ticket="this.ticketToCancel" ref="cancellationDisplay" @cancelled-ticket="handleTicketCancellation"/>
            <template v-slot:actions>
                <!-- no buttons placeholder -->
                <label></label>
            </template>
        </PopUp>        
    </div>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css'
import VueDatePicker from '@vuepic/vue-datepicker'

import axios from 'axios'
import PopUp from '@elements/PopUp.vue'
import ListTicketDepiction from '@components/ListTicketDepiction.vue'
import CancellationDisplay from '@components/CancellationDisplay.vue'

const TICKET_STATE = {
    PREPARED:           1,
    PAID:               2,
    PARTIALLY_DEVALUED: 3,
    DEVALUED:           4,
    BLOCKED:            5,
    CANCELED:           6
}

export default {
    name: 'ticket-display',
    inject: [ 'util', 'session', 'hws' ],
    props: {
        tickets: {
            type: Array,
            required: true
        },
        withCustomer: {
            type: Boolean,
            default: false
        },
        salesDate: {
            type: Array
        },
        onlyValidBookings: {
            type: Boolean
        }
    },
    data() {
        return {
            TICKET_STATE,
            showCancel: false,
            ticketToCancel: {},
            activeTicket: undefined,
            editTicket: {
                from: undefined,
                to:   undefined
            }
        }
    },
    computed: {
        ticketInDetails() {
            return this.tickets.at(this.activeTicket)
        },
        filterSalesDate: {
            get() {
                return this.salesDate
            },
            set(val) {
                this.$emit('update:salesDate', val)
            }
        },
        shift() {
            return this.session.shift
        },
        deviceId() {
            return this.shift.device?.displayid
        },
        locationId() {
            return this.shift.location?.displayid
        },
        directionId() {
          return this.shift.location?.direction
        },
        ticketList() {
            return this.tickets
        }

    },
    watch: {
        ticketList() {
            this.closeAllActionsAndDetails()
        }
    },
    methods: {
        closeAllTicketActions() {
            // close all other open action dialogs
            Object.keys(this.$refs).forEach(key => {
                if (key.startsWith('ticket-')) {
                    const ticketDisplay = this.$refs[key]
                    const activeTicket = ticketDisplay?.at(0)
                    activeTicket ? activeTicket.actionsOpen = false : null
                }
            })
        },
        closeAllTicketDetails() {
            // close all other details
            Object.keys(this.$refs).forEach(key => {
                if (key.startsWith('ticket-')) {
                    const ticketDisplay = this.$refs[key]
                    const activeTicket = ticketDisplay?.at(0)
                    activeTicket ? activeTicket.detailsOpen = false : null
                }
            })
        },
        closeAllActionsAndDetails() {
            this.closeAllTicketDetails()
            this.closeAllTicketActions()
        },
        async updateTicketValidity(ticket, from, to) {
            let updateUrl = this.util.middleware()
            updateUrl += '/ticketing/ticket/' + ticket.orderId
            updateUrl += '/validity'

            const updateBody = {
                from,
                to
            }

            return axios.put(updateUrl, updateBody)
            .then(response => {
                this.handleTicketUpdate(response.data)
            })
            .catch(err => {
                //TODO user message from Middleware-Error
                this.$toast.error('Ticket Gültigkeit konnte nicht gesetzt werden: ' + err)
            })
        },
        async addLicensePlateToTicket(ticket, licensePlate) {
            if (ticket?.licensePlates.length >= 3) {
                return this.$toast.warn('Es können maximal 3 Kennzeichen einem Ticket zugeordnet werden')
            }

            let addUrl = this.util.middleware()
            addUrl += '/ticketing/ticket/' + ticket.orderId
            addUrl += '/license-plates'

            const addBody = {
                licensePlate
            }

            return axios.put(addUrl, addBody)
            .then(response => {
                this.handleTicketUpdate(response.data)
            })
            .catch(err => {
                //TODO user message from Middleware-Error
                this.$toast.error('Kennzeichen konnte nicht hinzugefügt werden: ' + err)
            })
        },
        async removeLicensePlateFromTicket(ticket, licensePlate) {
            let removeUrl = this.util.middleware()
            removeUrl += '/ticketing/ticket/' + ticket.orderId
            removeUrl += '/license-plates/' + encodeURIComponent(licensePlate)

            return axios.delete(removeUrl)
            .then(response => {
                this.handleTicketUpdate(response.data)
            })
            .catch(err => {
                //TODO user message from Middleware-Error
                this.$toast.error('Kennzeichen konnte nicht entfernt werden: ' + err)
            })
        },
        async updateTicket(ticket) {
            let updateUrl = this.util.middleware()
            updateUrl += '/ticketing/ticket/' + ticket.orderId

            return axios.put(updateUrl, ticket)
            .then(response => {
                this.handleTicketUpdate(response.data)
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        printTicket(ticket) {
            this.hwsPrint(ticket)
        },
        pushToManualBooking(type, ticket) {
            this.$router.push({ name: 'manual-booking', query : { type: type, ticketId: ticket.orderId } })
        },
        async createUsage(ticket) {
            let ticketUrl = this.util.middleware()
            ticketUrl += '/ticketing/ticket/' + ticket.orderId
            ticketUrl += '/usage/create'

            let outwardTrip = ticket.direction.id === this.directionId
            // Mehrfahrtenkarten sind nicht Richtungsabhängig und Verwenden immer die Hin-Richtung
            if (ticket.multipleJourney) {
               outwardTrip = true
            }
            
            const body = {
                direction: this.directionId,
                deviceId: this.deviceId,
                outwardTrip: outwardTrip
            }
            return axios.post(ticketUrl, body)
            .then(response => {
                this.handleTicketUpdate(response.data)
                this.$toast.success('Entwertung erfolgreich')
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async disableTicket(ticket) {
            let ticketUrl = this.util.middleware()
            ticketUrl += '/ticketing/ticket/' + ticket.orderId
            ticketUrl += '/disable'

            return axios.post(ticketUrl)
            .then(response => {
                this.handleTicketUpdate(response.data)
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async enableTicket(ticket) {
            let ticketUrl = this.util.middleware()
            ticketUrl += '/ticketing/ticket/' + ticket.orderId
            ticketUrl += '/enable'

            const body = {
                dataVersion: ticket.dataVersion
            }

            return axios.post(ticketUrl, body)
            .then(response => {
                this.handleTicketUpdate(response.data)
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async cancelTicket(ticket) {            
            this.ticketToCancel = ticket
            this.ticketToCancel.cancellationFee = await this.getCancellationFee(ticket)
            this.showCancel = true
       },
        async getCancellationFee(ticket) {
            if (ticket.productId) {
                let feeUrl = this.util.middleware()
                feeUrl += '/ticketing/products/'+ ticket.productId
                feeUrl += '/cancellationFee'

                return axios.get(feeUrl)
                .then(response => {
                    return response.data
                })
                .catch(err => {
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                })
            } else {
                return 0
            }
        }, 
        async cancelUsage(ticket, usage) {
            let ticketUrl = this.util.middleware()
            ticketUrl += '/ticketing/ticket/' + ticket.orderId
            ticketUrl += '/usage/' + usage.usageId
            ticketUrl += '/cancel'

            const body = {
                deviceId: this.deviceId
            }

            return axios.post(ticketUrl, body)
            .then(response => {
                this.handleTicketUpdate(response.data)
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async printCancellationReceipt(ticket) {
            let cancellationReceiptUrl = this.util.middleware()
            cancellationReceiptUrl += '/ticketing/ticket/' + ticket.orderId
            cancellationReceiptUrl += '/cancellationReceipt'            
            
            let body = {
                shiftId: this.shift.id,
                deviceId: this.deviceId,
                staffId: this.shift.staffNr,
                payment: ticket?.paymentData?.paymentData?.receipts[1]?.content || '',
                paymentMethod: this.util.formatPaymentMethodString(ticket.paymentMethod),
                cancellationFee: ticket.cancellationFee,
                refundSum: ticket.refundSum
            }
            return axios.post(cancellationReceiptUrl, body,{ responseType: 'arraybuffer' })
            .then(response => {
                if (this.hws.data.status === 0 && process.env.NODE_ENV === 'production') {
                    const img = new Uint8Array(response.data)
                    return this.hws.printImage(img)
                } else { 
                    // if HWS is not working or we are in dev - just show the image in the browser
                    const file    = new File([response.data], 'storno' + ticket.orderId + '.png', {type: 'image/png'})
                    const url     = URL.createObjectURL(file)
                    window.open(url) 
                }
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },    
        async hwsPrint(ticket) {
            let ticketImageUrl = this.util.middleware()
            ticketImageUrl += '/ticketing/createTicketImage'

            let body = {
                ticket: ticket,
                payment: '',
                paymentMethod: this.util.formatPaymentMethodString(ticket.paymentMethod),
                deviceId: this.deviceId,
                printDate: this.util.formatDisplayDateTime(new Date())
            }

            return axios.post(ticketImageUrl, body, { responseType: 'arraybuffer' })
            .then(response => {
                if (this.hws.data.status === 0 && process.env.NODE_ENV === 'production') {
                    const img = new Uint8Array(response.data)
                    return this.hws.printImage(img)
                } else { 
                    // if HWS is not working or we are in dev - just show the image in the browser
                    const file    = new File([response.data], 'ticket' + ticket.orderId + '.png', {type: 'image/png'})
                    const url     = URL.createObjectURL(file)
                    window.open(url) 
                }
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        handleCustomerSelection(customer) {
            this.$emit('update:selectedCustomer', customer)
        },
        handleTicketUpdate(ticket) {
            if (ticket) {
                this.$emit('update:ticket', ticket)
            }
        },
        async handleTicketCancellation(ticket) {
            this.showCancel = false
            let ticketUrl = this.util.middleware()
            ticketUrl += '/ticketing/ticket/' + ticket.orderId
            ticketUrl += '/cancel'
            
            let body = {
                staffId: this.shift.staffNr,
                deviceId: this.deviceId,
                withoutFee: ticket.withoutFee
            }
            return axios.post(ticketUrl, body)
            .then(response => {
                this.printCancellationReceipt(ticket)                 
                this.handleTicketUpdate(response.data)                               
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })            
        }
    },
    components: {
        PopUp,        
        VueDatePicker,
        ListTicketDepiction,
        CancellationDisplay
    },
}
</script>

<style lang="scss" scoped>
.ticket-display-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @mixin list-view-widths {
        > div {
            flex: 1;
        }

        > .actions-wrapper {
            max-width: 40px;
        }

        > .open-wrapper {
            max-width: 40px;
        }

        > .license-plates-wrapper {
            max-width: 100px;
        }

        > .state-wrapper {
            max-width: 120px;
        }
    }

    > .list-view-header {
        padding: 0 8px;
        display: flex;
        min-height: 48px;
        align-items: center;
        border-bottom: 1px solid #ccc;
        color: #666;
        font-size: 1.2em;

        > .name-wrapper {
            text-align: left;
        }

        @include list-view-widths;
    }

    >.ticket-list-container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        &.no-scroll {
            overflow: hidden;
        }

        >.ticket-list-wrapper {
            >.ticket-list {
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-bottom: 8px;

                &:has(.list-ticket-depiction-container.open) {
                    > .list-ticket-depiction-container:not(.open) {
                        background-color: #eee;
                    }
                }

                :deep(.list-view) {
                    @include list-view-widths;
                }
            }
        }
    }
}

// list transitions
.ticket-list-move,
/* apply transition to moving elements */
.ticket-list-enter-active,
.ticket-list-leave-active {
    transition: all .2s ease;
}

.ticket-list-enter-from,
.ticket-list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
</style>