// new data import syntax "import x from y with z" is not yet supported
// in eslint -> the error does not exist at runtime
import packageJson from '@packageJson' with { type: "json" }
const version = () => packageJson.version;

const middleware = () => {
    return window?.configs?.['VUE_APP_MIDDLEWARE_URL'] || process.env['VUE_APP_MIDDLEWARE_URL']
}

const formatCurrency = (valueInCent) => {
    const currencyString = (valueInCent/100).toFixed(2)
    return currencyString.replace('.', ',')
}

const formatDisplayDate = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return [
        twoDigitNumber(date.getDate()),
        twoDigitNumber(date.getMonth() + 1),
        date.getFullYear(),
    ].join('.');
}

const formatDisplayTime = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return [
        twoDigitNumber(date.getHours()),
        twoDigitNumber(date.getMinutes())
    ].join(':');
}

const formatDisplayDateTime = (date) => {
    return formatDisplayDate(date) + ' ' + formatDisplayTime(date)
}

const twoDigitNumber = (number) => {
    return ('0' + number).slice(-2)
}

const formatPaymentMethodString = (paymentMethod) => {
    switch(paymentMethod) {
        case 5:
            return "PayPal"
        case 11:
            return "Kartenzahlung"
        case 12:
            return "Kreditkarte"
        case 13:
            return "Visa"
        case 14:
            return "Mastercard"
        case 15:
            return "Amex"
        case 16:
            return "JCB"
        case 17:
            return "Diners"
        case 22:
            return "Barzahlung"
        case 25:
            return "Rechnung"
        case 26:
            return "Lastschrift"
        case 27:
            return "googlePay"
        case 28:
            return "applePay"
        default:
            return '--- undefined ---'
    }
}

const positionPopupElement = (parentEl, childEl) => {
    // get bounding rect of the container
    // then position the options beneath the container
    const parentBounding  = parentEl.getBoundingClientRect()
    const childBounding   = childEl.getBoundingClientRect()

    let top    = parentBounding.bottom
    let bottom = top + childBounding.height
    let left   = parentBounding.right - parentBounding.width - childBounding.width
    let right  = left + childBounding.width

    // if the menu would appear outside of the current viewport:
    if (bottom > window.innerHeight) {
        // below
        // 1. we try to place it above the parent
        top = parentBounding.bottom - parentBounding.height - childBounding.height
    }

    if (right > window.innerWidth) {
        // too far left
        // 2. and do the same for the width
        left = parentBounding.right - parentBounding.width - childBounding.width
    }

    childEl.style.top  = top + 'px'
    childEl.style.left = left + 'px'
}

const formatDisplayIban = (iban) => {
    const sanitizedInput = iban.replace(/[^0-9a-zA-Z]/g, '').toUpperCase()
    const formattedInput = sanitizedInput.replace(/(.{4})/g, '$1 ').trim()

    return formattedInput
}

export default {
    version,
    middleware,
    formatCurrency,
    formatDisplayDate,
    formatDisplayTime,
    formatDisplayDateTime,
    twoDigitNumber,
    formatPaymentMethodString,
    positionPopupElement,
    formatDisplayIban
}