<template>
    <div id="app">
        <NavigationHeader class="header" @license-plate="handleLicensePlateSelection" :class="{ 'hidden-customer': hideActiveCustomer }" :systemInfo="globalState.systemInfo"/>

        <ActiveCustomer class="active-customer" v-if="!hideActiveCustomer" :customer="globalState.customer" />

        <div class="content-wrapper">
            <div class="sub-page-back">
                <div class="back-wrapper" :class="{ 'hidden': isMainPage || explicitNoNav }" @click="goBack">
                    <div class="back">
                        <fa-icon icon="fa-solid fa-arrow-right" src="@img/arrow-right.svg" />
                        <label>Zurück</label>
                    </div>
                    <label class="current">{{ routeDisplay }}</label>
                </div>
            </div>
            <router-view id="router-view" :class="{ 'active-customer': !hideActiveCustomer }" />
        </div>

        <transition name="fade">
            <div class="loading" v-show="isLoading">
                <div class="spinner"></div>
                <div class="label">Lade Daten...</div>
            </div>
        </transition>

        <PopUp class="ticket-overview" title="Ticketübersicht" :open="showTicketOverview" @close="showTicketOverview = false">
            <TicketDisplay class="ticket-content" :tickets="this.tickets" :with-customer="true" @update:selectedCustomer="handleCustomerSelection" @update:ticket="handleTicketUpdate"/>

            <template v-slot:actions>
                <div class="actions">
                    <button @click="showTicketOverview = false">
                        <fa-icon icon="fa-solid fa-xmark" src="@img/xmark.svg" />
                        <label>Abbrechen</label>
                    </button>
                </div>
            </template>

        </PopUp>
    </div>
</template>

<script>
import PopUp from '@components/elements/PopUp.vue'
import ActiveCustomer from '@components/ActiveCustomer.vue'
import NavigationHeader from '@components/NavigationHeader.vue'
import TicketDisplay from '@components/TicketDisplay.vue'
import axios from 'axios'
import { getCustomer, findCustomers } from '@lib/middleware-connector'
import { monitorUserLogout } from '@lib/user-logout.js'

export default {
    name: 'app',
    inject: [ 'globalState', 'hws', 'util', 'session', 'keycloak' ],
    data() {
        return {
            showTicketOverview: false,
            tickets: []
        }
    },
mounted() {
        monitorUserLogout()
    },
    computed: {
        hwsBarcode() {
            return this.hws.data.barcode
        },
        hwsLicensePlate() {
            return this.hws.data.licensePlate
        },
        isLoading() {
            return this.globalState.isLoading
        },
        isMainPage() {
            // show back button if we're not on a page that is
            // accessible by the navigation
            return !!this.$route.meta.nav
        },
        explicitNoNav() {
            return this.$route.meta.explicitNoNav
        },
        routeDisplay() {
            return this.$route.meta.display
        },
        hideActiveCustomer() {
            return this.$route.meta?.hideCustomer
        }
    },
    methods: {
        goBack() {
            const parentPath = this.$route.meta.parentPath
            this.$router.push(parentPath)
        },
        async getTicketFromBarcode(barcode) {
            let ticketUrl = this.util.middleware()
            ticketUrl += '/ticketing/barcode'

            return axios.put(ticketUrl, [...barcode])
            .then(({ data }) => {
                this.tickets = [data]
                if (this.tickets.length){
                    this.tickets.map(entry => {
                        return this.addCustomerToTicket(entry)
                    })
                    this.showTicketOverview = true
                }
            })
        },
        async getTicketsFromLicensePlate(licensePlate) {
            let ticketUrl = this.util.middleware()
            ticketUrl += '/ticketing/licensePlate'

            return axios.put(ticketUrl, { licensePlate })
            .then(({ data }) => {
                this.tickets = data
                if (this.tickets.length){
                    this.tickets.map(entry => {
                        return this.addCustomerToTicket(entry)
                    })
                    this.showTicketOverview = true
                }
            })
        },
        async validateTickets(tickets) {
            let ticketUrl = this.util.middleware()
            ticketUrl += '/ticketing/validation'

            const body = {
                tickets: tickets.map(entry => entry.original),
                device: this.session.shift.device?.data
            }

            return axios.put(ticketUrl, body)
            .then(({ data }) => {
                return data
            })
        },
        async handleLicensePlateSelection(licensePlate) {
            await this.getTicketsFromLicensePlate(licensePlate)
            .catch(err => {
                this.$toast.error(err)
            })
            if (this.tickets.length) {
                const validationResponse = await this.validateTickets(this.tickets)
                .catch(err => {
                    this.$toast.error(err)
                })
                
                this.tickets.map(ticket => {
                    ticket.validation = validationResponse.find(validation => validation.listView.orderId === ticket.orderId)
                })
                this.tickets = this.tickets.sort((a, b) => {return a.validation.listView.weight - b.validation.listView.weight})
            }
        },
        async addCustomerToTicket(ticket) {
            ticket.customer = await this.getCustomerToReference(ticket.customerReference)
            return ticket
        },
        async getCustomerToReference(customerReference) {
            const possibleCustomers = await findCustomers({ npsUserIdToken: customerReference })
            return possibleCustomers?.length === 1 ? possibleCustomers[0] : undefined
        },
        async handleCustomerSelection(customerSearchResult) {
            if (customerSearchResult) {
                try {
                    const customer = await getCustomer(customerSearchResult.uuid)
                    Object.assign(this.globalState, { customer })
                    this.showTicketOverview = false
                } catch(err){
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                    return null
                }
            }
        },
        handleTicketUpdate(ticket) {
            const idx = this.tickets.findIndex((element) => element.orderId == ticket.orderId)
            if (idx > -1) {
                this.tickets.splice(idx, 1, ticket)
            }
            
            this.validateTickets([ticket])
            .then (data => {
                ticket.validation = data[0]
                if (idx > -1) {
                    this.tickets.splice(idx, 1, ticket)
                }
            })            
        }
    },
    components: {
        NavigationHeader,
        ActiveCustomer,
        TicketDisplay,
        PopUp
    },
    watch: {
        hwsBarcode(value) {
            if (value) {
                this.getTicketFromBarcode(value)
                    .catch(err => {
                        this.$toast.error(err)
                    })
            }
        },
        async hwsLicensePlate(value) {
            this.handleLicensePlateSelection(value)
        }
    }
}
</script>

<style lang="scss">
@import '@font/IstokWeb/import.css';
@import '@css/application.scss';

* {
    object-fit: contain;
}

button {
    align-self: center;
    padding: 0 16px;
    height: 30px;
    font-size: 1.25em;
    border-radius: 4px;
    color: #fff;
    fill: #fff;
    stroke: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    color: #000;

    &:hover:not(:disabled) {
        background-color: #00000022;
    }

    &:disabled {
        color: #00000066;
        border: 1px solid #00000022;
    }

    &.highlight {
        background-color: #ec0016;
        color: #fff;

        &:disabled {
            background-color: #282d37;
            opacity: .33;
        }

        &:hover:not(:disabled) {
            background-color: #5a6268;
        }
    }

    >svg {
        height: 50%;
    }

    >label {
        margin: 0 8px;
    }
}
</style>

<style scoped lang="scss">
#app {
    position: relative;

    >.header {
        z-index: 100;
    }

    >.active-customer {
        z-index: 90;
    }

    >.content-wrapper {
        // router-view is buffered by the sub-pack-back container
        // padding-top: 48px;
        z-index: 10;
        overflow-y: auto;
        padding: 0 64px;
        // full height minus sub-page back
        height: calc(100% - 98px);

        >.sub-page-back {
            width: 100%;
            height: 32px;
            margin: 8px auto;
            display: flex;
            align-items: center;

            >.back-wrapper {
                height: 100%;
                display: flex;

                &.hidden {
                    visibility: hidden;
                }

                >.back {
                    width: fit-content;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 1.2em;
                    font-weight: bold;
                    border-radius: 8px;
                    padding: 0 8px;

                    &:hover {
                        background-color: #00000022;
                    }

                    &:hover,
                    &:hover :deep(*) {
                        cursor: pointer;
                    }

                    >svg {
                        height: 50%;
                        transform: rotate(180deg);
                    }

                    >label {
                        margin-left: 4px;
                    }
                }

                >.current {
                    margin-left: 8px;
                    font-size: 1.5em;
                    font-weight: bold;
                    padding: 0 8px;
                    border-bottom: 2px solid #f01414;
                    line-height: 32px;
                }
            }
        }
    }
}

.loading {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #000000cc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;

    >.spinner {
        width: 50px;
        height: 50px;
        border: 4px solid #fff;
        border-bottom-color: #ffffff33;
        border-radius: 50%;

        animation-name: spin;
        animation-duration: 1500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    >.label {
        color: #fff;
        font-size: 1.4em;
        font-weight: bold;
        margin-top: 10px;
    }
}

.ticket-overview {
    .ticket-content {
        max-height: 600px;
        overflow: auto;
        margin-bottom: 20px;
        width: 1400px;
     }

    .actions {
        display: flex;
        justify-content: flex-end;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
