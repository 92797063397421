import Session from '@lib/session.js'
import { reactive } from 'vue'

const globalState = reactive({
    baseData:          undefined,
    isLoading:         false,
    customer:          undefined,
    products:          undefined,
    directions:        undefined,
    vehicles:          undefined,
    bookkeepingConfig: undefined,
    systemInfo:        undefined
})

const keycloakState = reactive({
    available: false,
    instance: undefined
})

const sessionState = reactive(new Session())

export {
    globalState,
    keycloakState,
    sessionState
}