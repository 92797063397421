import axios from 'axios'
import util from '@plugins/util'

const QUICK_SEARCH_FIELDS = {
    EMAIL: 'EMAIL',
    BIRTHDATE: 'BIRTHDATE',
    APP_INSTANCE_NUMBER: 'APP_INSTANCE_NUMBER',
    LICENCE_PLATE: 'LICENCE_PLATE',
    CUSTOMER_NUMBER: 'CUSTOMER_NUMBER',
    LAST_NAME_FIRST_NAME: 'LAST_NAME_FIRST_NAME',
    TICKET_ID: 'TICKET_ID'
}

/**
 * Searches for customers based on the given input
 *
 * @param {CustomerSearchInput} customerSearchInput Search input
 * @returns {Promise<CustomerSearchOutput[]>} Search output
 */
async function findCustomers(customerSearchInput) {
    let searchUrl = util.middleware()
    searchUrl += '/sylt-customers'

    return axios.get(searchUrl, {
        params: customerSearchInput
    })
    .then(response => {
        const data = response.data
        return data
    })
}

/**
 * Gets customer data
 *
 * @param {string} uuidCustomer Id of the customer to retrieve
 * @returns {Promise<Customer>} Customer
 */
async function getCustomer(uuidCustomer) {
    let getCustomerUrl = util.middleware()
    getCustomerUrl += '/sylt-customers/' + uuidCustomer

    return axios.get(getCustomerUrl)
    .then(async response => {
        if (response.status !== 200) {
            throw response.statusText
        }

        return response.data
    })
}

/**
 * Loads bank account holder
 *
 * @param {string} uuidBankAccountHolder Id of the bank account holder to retrieve
 * @returns {Promise<BankAccountHolder>} Customer
 */
async function getBankAccountHolder(uuidBankAccountHolder) {
    let loadBankAccountHolderUrl = util.middleware()
    loadBankAccountHolderUrl += '/sylt-customers'
    loadBankAccountHolderUrl += '/bankaccountholders/' + uuidBankAccountHolder

    return axios.get(loadBankAccountHolderUrl)
        .then(response => {
            return response.data
        })
}


export {
    findCustomers,
    getCustomer,
    getBankAccountHolder,
    QUICK_SEARCH_FIELDS
}